import dayjs from 'dayjs';
import Cookie from 'js-cookie';
import CacheStorage from '@/utils/cacheStorage';
import {
  TOKEN,
  REFRESH_TOKEN,
  LAST_REFRESH_TIME,
  TOKEN_VALID_DAY,
  USER_EMAIL,
  EDU_URL,
  USER_ID,
  SCH_URL,
} from '@/utils/constants';
import { isTourist } from './utils';
import { goWebSite } from '@/utils/goWebSite';

export const setTokenInfo = (token: string, refreshToken: string) => {
  CacheStorage.setItem(TOKEN, token);
  CacheStorage.setItem(REFRESH_TOKEN, refreshToken);
  CacheStorage.setItem(LAST_REFRESH_TIME, `${dayjs().add(TOKEN_VALID_DAY, 'day').unix()}`);
};

export const setRealToken = (token: string) => {
  CacheStorage.setItem(TOKEN, token);
  CacheStorage.setItem(LAST_REFRESH_TIME, `${dayjs().add(TOKEN_VALID_DAY, 'day').unix()}`);
};

export const removeRealToken = () => {
  Cookie.remove(TOKEN);
  CacheStorage.removeItem(LAST_REFRESH_TIME);
};

export const removeTokenInfo = (params?: any, quite = true) => {
  Cookie.remove(TOKEN);
  CacheStorage.removeItem(REFRESH_TOKEN);
  CacheStorage.removeItem(LAST_REFRESH_TIME);
  CacheStorage.removeItem(USER_EMAIL);
  CacheStorage.removeItem(USER_ID);
  window?.Intercom?.('shutdown');
  try {
    if (quite) {
      goWebSite();
    }
    Cookie.remove(TOKEN, {
      domain: EDU_URL,
      path: '/',
    });
    Cookie.remove(TOKEN, {
      domain: SCH_URL,
      path: '/',
    });
    window?.FB?.logout();
    window?.google?.accounts?.id?.cancel();
  } catch (e) {
    console.log(e);
  }
};

export const checkTokenInfo = () => {
  const token = CacheStorage.getItem(TOKEN);

  return !!token && !isTourist();
};

export const generateNamePrefix = (prefix: string) => {
  return (string: any, ...params: any[]): string => {
    return [prefix, ...string, ...params].join('');
  };
};

/**
 * 存储本地数据
 * @param {String} type 存储类型sessionStorage\localStorage
 * @param {String} key 存储的key
 * @param { Object } value 存储的值
 */
export const setStorage = (type: 'sessionStorage' | 'localStorage' = 'localStorage', key: string, value: unknown) => {
  try {
    if (key) {
      const formatValue = JSON.stringify(value);
      if (!isServerSide()) {
        window[type].setItem(key, formatValue);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 判断浏览器环境
 * @return { Boolean } 是否是服务端
 */
export const isServerSide = () => {
  return typeof window === 'undefined';
};

/**
 * 获取存储本地数据
 * @param {String} type 存储类型sessionStorage\localStorage
 * @param {String} key 存储的key
 * @return {any}
 */
export const getStorage = (type: 'sessionStorage' | 'localStorage' = 'localStorage', key: string): any => {
  try {
    if (!isServerSide()) {
      const value = window[type].getItem(key) || 'null';
      return JSON.parse(value);
    }
    return null;
  } catch (error: any) {
    return Error(error);
  }
};

/**
 * 删除存储本地数据
 * @param {String} type 存储类型sessionStorage\localStorage
 * @param {String} key 存储的key
 *
 */
export const delStorage = (type: 'sessionStorage' | 'localStorage' = 'localStorage', key: string) => {
  try {
    if (key) {
      if (!isServerSide()) {
        window[type].removeItem(key);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const isNumber = (value: any) => {
  if (value === null) {
    return false;
  }
  return !isNaN(Number(value));
};
