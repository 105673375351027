/* eslint-disable func-style */
import Cookie from 'js-cookie';
import { EDU_URL, SCH_URL } from './constants';

/**
 * Storage 仅在频繁都写中使用该 CacheStorage eg: axios 读写操作
 */
let store: Record<string, string> = {};

const setStoreItem = (key: string, value: string) => {
  store = { ...store, [key]: value };
};

const getDomain = () => {
  const h = window.location.hostname;
  const b = h.includes(SCH_URL);
  const domain = b ? SCH_URL : EDU_URL;
  return domain;
};

function getItem<T = string>(key: string, initialValue = ''): T | string {
  if (!store[key]) {
    setStoreItem(key, Cookie.get(key) || '');
  }
  try {
    return store[key] ? JSON.parse(store[key]) : initialValue;
  } catch {
    return store[key] || initialValue;
  }
}
/**
 * 删除cookies
 */
const removeItem = (key: string) => {
  if (store[key]) {
    delete store[key];
  }
  Cookie.remove(key);
  if (['token', 'NEXT_LOCALE', 'refresh_token', 'last_refresh_time'].includes(key)) {
    Cookie.remove(key, { domain: getDomain() });
  }
};

function setItem<T>(key: string, value: T) {
  const result: string = (typeof value === 'object' ? JSON.stringify(value) : value) as string;
  setStoreItem(key, result);
  if (['token', 'NEXT_LOCALE', 'refresh_token', 'last_refresh_time'].includes(key)) {
    Cookie.set(key, result, { expires: 30, domain: getDomain() });
  } else {
    Cookie.set(key, result, { expires: 30 });
  }
}

export default {
  getItem,
  setItem,
  removeItem,
};
