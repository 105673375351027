import ReactDOM from 'react-dom';

import { IApiModal } from './interface';

abstract class CommonApiModal<T> implements IApiModal<T> {
  options: T;

  apiModalContainer: HTMLDivElement;

  constructor(options: T) {
    this.options = options;

    this.apiModalContainer = document.createElement('div');

    this.render(true, options);
  }

  abstract render(visible: boolean, options: T): void;

  destroy(): void {
    ReactDOM.unmountComponentAtNode(this.apiModalContainer);
  }

  update(options: T): void {
    this.render(false, options);
  }

  close(): void {
    this.render(false, this.options);
  }
}

export default CommonApiModal;
