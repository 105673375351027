import React from 'react';
import cls from 'classnames';

import starIcon from '@public/imgs/star-icon.svg';
import closeIcon from './modal-close-icon.svg';
import Modal from '../Modal/modal';
import styles from './style.module.scss';

export interface CustomModalProps {
  title?: string;
  visible: boolean;
  onClose(): void;
  bodyClassName?: string;
  showClose?: boolean;
  className?: string;
  closeClassName?: string;
}

const CustomModal = (props: React.PropsWithChildren<CustomModalProps>) => {
  const { title, onClose, children, visible, bodyClassName, className, showClose = true, closeClassName } = props || {};

  const renderTitle = () => {
    return (
      <div className={styles.headerBox}>
        {title && (
          <>
            <div className={styles.boxLine} />
            <div className={styles.boxTitle}>
              <img src={starIcon.src} alt="" />
              <span>{title}</span>
              <img src={starIcon.src} alt="" />
            </div>
          </>
        )}
        {showClose && (
          <div
            role="button"
            onClick={(e) => {
              onClose();
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <img src={closeIcon.src} className={cls(styles.close, closeClassName)} alt="" />
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      renderTitle={renderTitle}
      bodyClassName={bodyClassName}
      className={cls(styles.modalWrapper, className)}
      closeBtn={false}
      visible={visible}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
