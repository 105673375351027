import React from 'react';
import cls from 'classnames';

import styles from './style.module.scss';

interface MaskProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  style?: React.CSSProperties;
  zIndex?: number;
}
const Element: React.FC<MaskProps> = ({ children, style, className, zIndex, ...p }) => {
  return (
    <div
      {...p}
      className={cls(styles.maskContainer, className)}
      style={{
        ...style,
        zIndex,
      }}
    >
      {children}
    </div>
  );
};

export default Element;
