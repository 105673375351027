import Image from 'next/image';
import defaultAdvisorAvatarImg from 'public/imgs/class-guidelines/default-advisor-avator.jpeg';
import cls from 'classnames';
import styles from './index.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import qs from 'query-string';
import mWhatsAppsImg from 'public/imgs/class-guidelines/m-whatsapp-icon.png';
import mWechatImg from 'public/imgs/class-guidelines/m-wechat-icon.png';
import whatsAppImg from '@public/imgs/class-guidelines/whatsapp_icon.svg';
import {
  ACADEMIC_ADVISOR_POP,
  ACADEMIC_ADVISOR_POP_ADD_WECHAT,
  ACADEMIC_ADVISOR_POP_ADD_WHATSAPP,
  ACADEMIC_ADVISOR_POP_CLICK,
  ACADEMIC_ADVISOR_POP_HOVER,
  ACADEMIC_ADVISOR_POP_SCAN_WHATSAPP,
  CHINESE,
  CURRENT_ACCOUNT_ID,
  CURRENT_STUDENT_CODE,
  QR_CODE_WHATSAPP,
  STUDENT_MOBILE,
  STUDENT_WEB,
} from '@/utils/constants';
import { getAcademicAdvisorInfo } from '@/api/home';
import { AdvisorInfo } from '@/api/app';
import { traceEvent } from '@wk/wk-gatherer';
import QRCode from 'qrcode.react';
import useUser from '@/store/useUser';

const AcademicAdvisor = () => {
  const { t, i18n } = useTranslation('home');
  const isChinese = i18n.language === CHINESE;
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const [mModalVisible, setMModalVisible] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [advisorInfo, setAdvisorInfo] = useState<AdvisorInfo>();
  const [isPc, setIsPc] = useState(true);
  const { curStudent } = useUser();
  const isPCRef = useRef<boolean>();

  const getAdvisorInfo = async () => {
    const res = await getAcademicAdvisorInfo({ scanType: STUDENT_WEB });
    setAdvisorInfo(res);
    res?.homePageShow &&
      traceEvent({
        data: {
          ...getBaseTrackData(),
          _event: ACADEMIC_ADVISOR_POP,
          source: isPCRef.current ? STUDENT_WEB : STUDENT_MOBILE,
        },
      });
  };

  const imageSettings = {
    src: whatsAppImg.src,
    height: 17,
    width: 17,
    excavate: true,
  };

  const getBaseTrackData = () => {
    return {
      _event: ACADEMIC_ADVISOR_POP_SCAN_WHATSAPP,
      source: STUDENT_WEB,
      account_id: window.sessionStorage.getItem(CURRENT_ACCOUNT_ID),
      Code: window.sessionStorage.getItem(CURRENT_STUDENT_CODE),
    };
  };

  useEffect(() => {
    isPCRef.current = window.innerWidth >= 720;
    setIsPc(isPCRef.current);
    getAdvisorInfo();
    const { Code, ...rest } = getBaseTrackData();
    const url = qs.stringifyUrl({
      url: `${window.location.origin}/whatsApp`,
      query: { ...rest, studentCode: Code },
    });
    setQrCodeValue(url);
  }, [curStudent]);

  // pc端鼠标移入
  const onmouseover = () => {
    traceEvent({
      data: {
        ...getBaseTrackData(),
        _event: ACADEMIC_ADVISOR_POP_HOVER,
        source: STUDENT_WEB,
      },
    });
    setQrcodeVisible(true);
  };
  // pc端鼠标移出
  const onmouseleave = () => {
    setQrcodeVisible(false);
  };

  // 手机端点击
  const handleAdvisorClick = () => {
    mModalVisible &&
      traceEvent({
        data: {
          ...getBaseTrackData(),
          _event: ACADEMIC_ADVISOR_POP_CLICK,
          source: STUDENT_MOBILE,
        },
      });
    setMModalVisible(!mModalVisible);
  };
  const openWhatsApp = () => {
    traceEvent({
      data: {
        ...getBaseTrackData(),
        _event: ACADEMIC_ADVISOR_POP_ADD_WHATSAPP,
        source: STUDENT_MOBILE,
      },
    });
    window.open(QR_CODE_WHATSAPP);
  };
  const openWechat = () => {
    traceEvent({
      data: {
        ...getBaseTrackData(),
        _event: ACADEMIC_ADVISOR_POP_ADD_WECHAT,
        source: STUDENT_MOBILE,
      },
    });
    window.open(advisorInfo?.ownerInfo?.quickJumpOwnerUrl);
  };
  return (
    <>
      {advisorInfo?.homePageShow ? (
        <div className={styles.academicAdvisorContainer}>
          {isPc ? (
            <div className={styles.pcContent}>
              <div className={styles.btn} onMouseOver={onmouseover} onMouseLeave={onmouseleave}>
                <Image
                  className={styles.avator}
                  width={48}
                  height={48}
                  src={
                    (isChinese ? advisorInfo?.ownerInfo?.qwAvatar : advisorInfo?.ownerInfo?.avatar) ||
                    defaultAdvisorAvatarImg
                  }
                  layout="responsive"
                  alt="advisor"
                />
              </div>
              <div className={cls(styles.dialog)}>
                {isChinese ? (
                  <>
                    <span className={styles.intro}>{t('专属学习规划师-您好') + t('专属学习规划师')}</span>
                    <span className={styles.intro}>{advisorInfo?.ownerInfo?.name || t('专属学习规划师-默认名字')}</span>
                  </>
                ) : (
                  <>
                    <span className={styles.intro}>
                      {t('专属学习规划师-您好', {
                        name: advisorInfo?.ownerInfo?.englishName || t('专属学习规划师-默认名字'),
                      })}
                    </span>
                    <span className={styles.intro}>{t('专属学习规划师')}</span>
                  </>
                )}
              </div>
              {qrcodeVisible && (
                <div className={styles.qrcodeContainer}>
                  {isChinese ? (
                    <>
                      <p className={styles.title}>{t('专属学习规划师-您好') + t('专属学习规划师')}</p>
                      <p className={styles.title}>{advisorInfo?.ownerInfo?.name || t('专属学习规划师-默认名字')}</p>
                    </>
                  ) : (
                    <>
                      <p className={styles.title}>
                        {t('专属学习规划师-您好', {
                          name: advisorInfo?.ownerInfo?.englishName || t('专属学习规划师-默认名字'),
                        })}
                        ,
                      </p>
                      <p className={styles.title}>{t('专属学习规划师')}</p>
                    </>
                  )}
                  <div className={styles.addTypes}>
                    <div className={styles.intro}>
                      {advisorInfo?.showWA ? t('添加专属学习规划师-两种方式') : t('添加专属学习规划师-微信')}
                    </div>
                    <div className={styles.qrcodeBox}>
                      {advisorInfo?.showWA && (
                        <div className={styles.qrItem}>
                          <div className={styles.code}>
                            <QRCode value={qrCodeValue} size={70} imageSettings={imageSettings as any} />
                          </div>
                          <span>{t('自主化-上课须知-添加学习规划师-whatsApp')}</span>
                        </div>
                      )}
                      <div className={styles.qrItem}>
                        <img
                          className={styles.code}
                          src={advisorInfo?.ownerInfo?.ownerQwShortCode || ''}
                          alt="WeChat"
                        />
                        <span>{t('自主化-上课须知-添加学习规划师-wechat')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.mobileContent}>
              <div className={styles.btn} onClick={handleAdvisorClick}>
                <Image
                  className={styles.avator}
                  src={
                    (isChinese ? advisorInfo?.ownerInfo?.qwAvatar : advisorInfo?.ownerInfo?.avatar) ||
                    defaultAdvisorAvatarImg
                  }
                  layout="responsive"
                  alt="advisor"
                />
              </div>
              {mModalVisible && (
                <div className={styles.qrcodeContainer}>
                  {isChinese ? (
                    <p className={styles.title}>
                      {t('专属学习规划师-您好') +
                        t('专属学习规划师') +
                        (advisorInfo?.ownerInfo?.name || t('专属学习规划师-默认名字'))}
                    </p>
                  ) : (
                    <>
                      <p className={styles.title}>
                        {`${t('专属学习规划师-您好', {
                          name: advisorInfo?.ownerInfo?.englishName || t('专属学习规划师-默认名字'),
                        })}, ${t('专属学习规划师')}`}
                      </p>
                    </>
                  )}
                  <div className={styles.addTypes}>
                    <div className={styles.intro}>
                      {advisorInfo?.showWA ? t('添加专属学习规划师-两种方式') : t('添加专属学习规划师-微信')}
                    </div>
                    <div className={styles.qrcodeBox}>
                      {advisorInfo?.showWA && (
                        <>
                          <div className={styles.qrItem} onClick={openWhatsApp}>
                            <div className={styles.code}>
                              <Image src={mWhatsAppsImg} alt="WhatsApp" layout="responsive" />
                            </div>
                            <span className={styles.whatsappText}>{t('自主化-上课须知-添加学习规划师-whatsApp')}</span>
                          </div>
                          <div className={styles.split}></div>
                        </>
                      )}
                      <div className={styles.qrItem} onClick={openWechat}>
                        <div className={styles.code}>
                          <Image src={mWechatImg} alt="WeChat" layout="responsive" />
                        </div>
                        <span className={styles.wechatText}>{t('自主化-上课须知-添加学习规划师-wechat')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
export default AcademicAdvisor;
