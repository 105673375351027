import cookie from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

interface UserData {
  studentId: string;
  code: string;
  associatedWeChat: boolean;
  studentLevel: number;
  associatedStudents: boolean;
  platform: string;
  status: string;
}

export interface TokenInfo {
  sub: string;
  authorities: string[];
  name: string;
  exp: number;
  phone: string;
  email: string;
  accountStatus: string;
  departments: string[];
  data: UserData;
}

export const getTokenInfo = () => {
  const token = cookie.get('token');
  if (!token) return null;
  try {
    const tokenInfo = jwtDecode(token);
    if (tokenInfo) return tokenInfo as TokenInfo;
  } catch (e) {
    return null;
  }
};
