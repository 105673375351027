// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SYSTEM_LANGUAGE {
  CHINESE = 'zh',
  ENGLISH = 'en',
}

export enum SUBJECT {
  ALL = 'ALL',
  CHINESE = 'CHINESE',
  MATH = 'MATH',
  ENGLISH = 'ENGLISH',
}

export enum DEVICE {
  PC = 1,
  IOS = 2,
  ANDROID = 3,
}
