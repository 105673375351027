/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react';
import { VideoJsPlayerOptions } from 'video.js';
import cls from 'classnames';

export interface VideoProps {
  src: string;
  className?: string;
  controls?: boolean;
  autoPlay?: boolean;
  videoOpts?: VideoJsPlayerOptions;
}

const Video = (props: VideoProps) => {
  const { src, className, controls, autoPlay } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <video
      ref={videoRef}
      autoPlay={autoPlay}
      className={cls(className)}
      controls={controls}
      controlsList="nodownload"
      disablePictureInPicture
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      src={src}
    />
  );
};

export default Video;
