import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import CacheStorage from '@/utils/cacheStorage';
import { USER_EMAIL } from '@/utils/constants';

export const USER_TIMEZONE_CONTEXT = 'USER_TIMEZONE';

export const setSentryUser = (user: Sentry.User) => {
  Sentry.setUser({ ...user, email: CacheStorage.getItem(USER_EMAIL) });
};

export const setSentryContext = (key: string, value: Record<string, string>) => {
  Sentry.setContext(key, value);
};

/**
 * 开启慢接口统计事务
 *
 * @param {*} requestConfig
 */
export const startTransaction = (requestConfig: any) => {
  const title = `${requestConfig.method.toUpperCase()} ${requestConfig.url}`;
  const transaction = Sentry.startTransaction({
    name: title,
    op: '慢接口统计',
    description: title,
  });
  // eslint-disable-next-line no-param-reassign
  requestConfig.metadata = { transaction, startTime: new Date().getTime() };
};

/**
 * 结束慢接口统计事务
 *
 * @param {*} res
 */
export const finishTransaction = (res: any) => {
  const { startTime, transaction } = res?.config?.metadata || {};
  const endTime = new Date().getTime();
  if (transaction && startTime && endTime - startTime >= 900) {
    transaction.setContext('http', {
      method: `${res.config.method.toUpperCase()}`,
      requestTime: dayjs(new Date(Number(startTime))).format('YYYY-MM-DD HH:mm:ss'),
      responseTime: `${endTime - startTime}ms`,
      requestURL: res.request.requestURL,
    });
    transaction.finish();
  }
};
