import React from 'react';
import cls from 'classnames';
import Image from 'next/image';

import loadingIcon from '@public/imgs/loading.svg';
import btnIcon from '@public/imgs/button-icon.svg';
import styles from './style.module.scss';

export type ButtonColor = 'red' | 'yellow' | 'white' | 'gray' | 'blue' | 'green';
export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'size'> {
  disabled?: boolean;
  color?: ButtonColor;
  loading?: boolean;
  ghost?: boolean;
  shieldIcon?: boolean; // 为 true 屏蔽按钮的 ~
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    color = 'red',
    type = 'button',
    loading = false,
    ghost = false,
    className,
    children,
    onClick,
    shieldIcon,
    ...restProps
  } = props || {};

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (loading) {
      event.preventDefault();
    } else {
      onClick?.(event);
    }
  };

  return (
    <button
      type={type}
      className={cls(
        className,
        styles.button,
        styles[`button-${color}`],
        ghost && styles[`button-${color}-ghost`],
        loading && styles.buttonLoading,
        restProps.disabled && [styles.buttonDisabled, styles[`button-${color}-disable`]],
      )}
      onClick={handleClick}
      {...restProps}
    >
      {loading && (
        <div className={styles.loading}>
          <Image width={24} src={loadingIcon} />
        </div>
      )}
      {children}
      {!shieldIcon && <img className={styles.btn} src={btnIcon.src} alt="" />}
    </button>
  );
};

export default Button;
