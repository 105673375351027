/* eslint-disable import/no-cycle */
import ReactDOM from 'react-dom';
import React from 'react';
import { IModalInfoParams } from './interface';
import CommonApiModal from './commonApiModal';

import Modal from './index';
import styles from './style.module.scss';

class ApiInfo extends CommonApiModal<IModalInfoParams> {
  render(visible: boolean, options: IModalInfoParams): void {
    const {
      title,
      content,
      image,
      className = styles.modalInfoContainer,
      bodyClassName = styles.modalInfoBody,
      imageStyle,
      disableBodyScroll,
      mask,
      closeBtn = true,
      onClose,
      zIndex,
      maskZIndex,
    } = options;

    ReactDOM.render(
      <Modal
        zIndex={zIndex}
        maskZIndex={maskZIndex}
        visible={visible}
        className={className}
        bodyClassName={bodyClassName}
        title={title}
        mask={mask}
        closeBtn={closeBtn}
        onExited={(): void => this.destroy()}
        image={image}
        imageStyle={imageStyle}
        disableBodyScroll={disableBodyScroll}
        onClose={(): void => {
          onClose?.();
          this.render(false, options);
        }}
      >
        {content}
      </Modal>,
      this.apiModalContainer,
    );
  }
}

export default ApiInfo;
