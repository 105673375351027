import React, { useState, useEffect, useRef } from 'react';
import cls from 'classnames';
import styles from './style.module.scss';

interface PopoverProps {
  content: React.ReactNode | (() => React.ReactNode);
  title?: React.ReactNode | (() => React.ReactNode);
  arrow?: boolean | { pointAtCenter: boolean };
  placement?: 'top' | 'left' | 'right' | 'bottom';
  trigger?: 'click' | 'hover' | 'focus';
  onOpenChange?: (visible: boolean) => void;
  onMouseEnter?: () => void;
}

const Popover: React.FC<PopoverProps> = ({
  content,
  title,
  arrow = true,
  placement = 'top',
  children,
  onOpenChange,
  trigger = 'hover',
  onMouseEnter,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [popoverContentStyle, setPopoverContentStyle] = useState({});
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (targetRef.current) {
        const targetHeight = targetRef.current.getBoundingClientRect().height;
        // 根据目标高度调整位置
        if (placement === 'top') {
          setPopoverContentStyle({
            bottom: `${targetHeight + 10}px`,
          });
        } else if (placement === 'bottom') {
          setPopoverContentStyle({
            top: `${targetHeight + 6}px`,
          });
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    onOpenChange?.(isVisible);
  }, [isVisible]);

  const handleMouseEnter = () => {
    trigger === 'hover' && setIsVisible(true);
    onMouseEnter?.();
  };
  const handleClick = () => {
    trigger === 'click' && setIsVisible(true);
  };
  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const renderContent = () => {
    if (typeof content === 'function') {
      return content();
    }
    return content;
  };

  const renderTitle = () => {
    if (typeof title === 'function') {
      return title();
    }
    return title;
  };

  return (
    <div className={styles.popoverWrapper}>
      <div
        className={styles.popoverTarget}
        ref={targetRef}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>

      <div
        className={cls(styles.popoverContent, isVisible && styles.show, styles[placement])}
        style={popoverContentStyle}
      >
        {title && <div className={styles.popoverTitle}>{renderTitle()}</div>}
        {content && <div className={styles.popoverBody}>{renderContent()}</div>}
        {arrow && <div className={styles.popoverArrow} />}
      </div>
    </div>
  );
};

export default Popover;
