import qs from 'query-string';
import dayjs from 'dayjs';
import 'dayjs/locale/zh';
import Cookie from 'js-cookie';
import { SYSTEM_LANGUAGE } from './enum';
import { VerifyType } from '@/api/home';
import { StudentInfo } from '@/api/types/home';
import { getTokenInfo } from '@/utils/parseToken';
import { EDU_URL, SCH_URL, TOKEN } from './constants';
import cacheStorage from './cacheStorage';

export const generateId = (): any => {
  const cookieId = localStorage.getItem('cookie_id');
  if (!cookieId) {
    const newCookieId = `cookie_id${new Date().getTime()}`;
    localStorage.setItem('cookie_id', newCookieId);
  }
  return cookieId;
};

export const generateUuid = (length = 8) => {
  const str = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  let res = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    const id = Math.ceil(Math.random() * 35);
    res += str[id];
  }

  return res;
};

export const exitWebView = () => {
  window?.webkit?.messageHandlers?.BACK?.postMessage?.('');
  window?.AppBridge?.BACK?.('');
};

// eg {"type":"INTERNAL","path":"class","customData":{"date":"2024-04-10"}}
export const appJumpPage = (objStr: string) => {
  window?.webkit?.messageHandlers?.CallNative_JumpARouter?.postMessage?.(objStr);
  window?.AppBridge?.CallNative_JumpARouter?.(objStr);
};

export const refreshStudentInfo = () => {
  window?.webkit?.messageHandlers?.CallNative_RefreshStudent?.postMessage?.('');
  window?.AppBridge?.CallNative_RefreshStudent?.('');
};

export const getIsAndroid = (): boolean => {
  const u = window?.navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
};

export const getIsIOS = (): boolean => {
  const u = window?.navigator.userAgent;
  /*
   * ipad环境判断更新
   * iOS pre 13 以前以ua作判断，13后以platform及maxTouchPoints做判断
   */
  const isiPad =
    navigator.userAgent.match(/(iPad)/) || (navigator?.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  return !!(u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ?? isiPad);
};

export const isMac = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /mac os/.test(userAgent);
};

export const forbiddenBrowser = (): boolean => {
  const ua = navigator.userAgent.toLowerCase();
  return /msie/.test(ua) || /firefox/.test(ua) || /opera|opr/.test(ua);
};

export const isiPad = () => {
  const ua = navigator.userAgent;
  const isSafari = ua.indexOf('Safari') !== -1 && ua.indexOf('Version') !== -1;
  const isIphone = ua.indexOf('iPhone') !== -1 && ua.indexOf('Version') !== -1;
  return isSafari && !isIphone && 'ontouchend' in document;
};

export const isAndroidPad = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return getIsAndroid() && !/(?:Mobile)/.test(userAgent);
};

export const isTablet = () => {
  return isiPad() || isAndroidPad();
};

// 获取ios设备信息
export const getIOSdeviceInfo = () => {
  return window?.AppBridge || {};
};

export const getIsMiniProgram = (): boolean => {
  return window?.navigator.userAgent.toLowerCase().indexOf('miniprogram') !== -1;
};

export const checkBrowerType = () => {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
};

export const toHour = (date: string): string => {
  return dayjs(date).format('HH:mm');
};

export const toDay = (date: string, locale?: string, format?: string): string => {
  if (!date) {
    return '';
  }
  if (locale === SYSTEM_LANGUAGE.CHINESE) {
    return dayjs(date, { locale: 'zh' }).format(format || 'dddd YYYY年M月D日');
  }
  return dayjs(date).format(format || 'dddd MMM DD, YYYY');
};

export const toDayWithTime = (date: string, locale?: string, format?: string): string => {
  if (!date) {
    return '';
  }
  if (locale === SYSTEM_LANGUAGE.CHINESE) {
    return dayjs(date, { locale: 'zh' }).format(format || 'dddd YYYY年M月D日 HH:mm:ss');
  }
  return dayjs(date).format(format || 'dddd MMM DD, YYYY HH:mm:ss');
};

export const toMobileDay = (date: string, locale?: string, format?: string): string => {
  return toDay(date, locale, format);
};

/**
 * 手机号脱敏
 *
 * @param {*} phoneNumber
 * @return {*}
 */
export const maskPhone = (phoneNumber: string) => {
  // 使用正则表达式匹配手机号码
  const reg = /^(.+)\d{4}(\d{2})$/;
  const matches = phoneNumber.match(reg);

  // 如果匹配成功，则对手机号码进行脱敏处理
  if (matches) {
    const maskedPhone = `${matches[1]}****${matches[2]}`;
    return maskedPhone;
  }

  // 如果匹配失败，则返回空
  return '';
};

/**
 * 邮箱脱敏
 *
 * @param {*} email
 * @return {*}
 */
export const maskEmail = (email: string) => {
  // 使用正则表达式匹配邮箱地址
  const reg = /^([a-zA-Z0-9._-]+)@([a-zA-Z0-9_-]+)(\.[a-zA-Z0-9_-]+)+$/;
  const matches = email?.match?.(reg);

  // 如果匹配成功，则对邮箱地址进行脱敏处理
  if (matches) {
    const username = matches[1];
    const domain = matches[2];
    const end = matches[3];
    const maskedEmail = `${username.slice(0, 3)}****@${domain}${end}`;
    return maskedEmail;
  }

  // 如果匹配失败，则返回空
  return '';
};

export const copyToClipboard = (str: string) => {
  return new Promise((resolve, reject) => {
    if (str === '') {
      reject(new Error('没有复制内容'));
    }
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    resolve(true);
  });
};
export default {};

// app打开企微
export const openMiniProgramPage = (url: string) => {
  if (url) {
    window?.webkit?.messageHandlers?.LAUNCH_MINI_PROGRAM?.postMessage?.(url);
    window?.AppBridge?.LAUNCH_MINI_PROGRAM?.(url);
  }
};

// 约课成功后传递数据给app
export const sentBookClassInfoToApp = (data: any) => {
  if (data) {
    const dataJSON = JSON.stringify(data);
    window?.webkit?.messageHandlers?.COURSE_RESERVE_SUCCESS?.postMessage?.(dataJSON);
    window?.AppBridge?.COURSE_RESERVE_SUCCESS?.(dataJSON);
  }
};
// app发送邮件
export const sentEmailToApp = (data: { email: string; status: VerifyType }) => {
  if (data) {
    const dataJSON = JSON.stringify(data);
    window?.webkit?.messageHandlers?.CallNative_ParentalVerification?.postMessage?.(dataJSON);
    window?.AppBridge?.CallNative_ParentalVerification?.(dataJSON);
  }
};

/**
 * 将base64字符串转换为json对象
 *
 * @param {string} params
 * @return {*}
 */
export const decodeAndParseParams = (params: string) => {
  try {
    if (!params) {
      return {};
    }
    return JSON.parse(decodeURIComponent(window.atob(params)));
  } catch (error) {
    console.error('Failed to parse params:', error);
    return {};
  }
};

/**
 * 判断是否为游客模式
 *
 * @return {boolean}
 */
export const isTourist = () => {
  const { params } = qs.parse(globalThis.location?.search || '');
  const { accessMode } = decodeAndParseParams(params as string);
  return accessMode === 'tourist';
};

/**
 * 获取URL上params里面的值
 *
 * @return {*}
 */
export const getParams = () => {
  const { params } = qs.parse(globalThis.location?.search || '');
  return decodeAndParseParams(params as string);
};

//  滚动到指定位置
export const scrollToPos = (id: string) => {
  // 找到锚点
  let anchorElement = document.getElementById(id);
  // 如果对应id的锚点存在，就跳转到锚点
  if (anchorElement) {
    anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
};

//   解析富文本html生成json渲染-学情中心
export interface ContentNode {
  type: string;
  content: string;
  children: ContentNode[];
  ellipsisShow: boolean; // 多行文本溢出，是否显示
}

export const parseHTML2Json = (htmlString: string): ContentNode[] => {
  if (!htmlString) return [];
  const regex = /<h1[^>]*>(.*?)<\/h1>([\s\S]*?)(?=<h1|$)/g;
  const h2Regex = /<h2[^>]*>(.*?)<\/h2>([\s\S]*?)(?=<h2|<h1|$)/g;
  const pRegex = /<p[^>]*>(.*?)<\/p>([\s\S]*?)(?=<p|<h2|$)/g;
  const pH1Regex = /<p[^>]*>(.*?)<\/p>([\s\S]*?)(?=<p|<h1|$)/g;
  const matches: ContentNode[] = [];
  let match: RegExpExecArray | null;
  //  多行溢出仅显示4行
  let ellipsis = 4;

  while ((match = regex.exec(htmlString)) !== null) {
    const h1Content: ContentNode = {
      type: 'h1',
      content: match[1].trim(),
      children: [],
      ellipsisShow: ellipsis > 0,
    };
    ellipsis--;
    let h2Match: RegExpExecArray | null;
    const h2ContentString = match[2].trim();
    while ((h2Match = h2Regex.exec(h2ContentString)) !== null) {
      const h2Content: ContentNode = {
        type: 'h2',
        content: h2Match[1].trim(),
        children: [],
        ellipsisShow: ellipsis > 0,
      };
      ellipsis--;
      h1Content.children.push(h2Content);
      let pMatch: RegExpExecArray | null;
      const pContentString = h2Match[2].trim();
      while ((pMatch = pRegex.exec(pContentString)) !== null) {
        const pContent: ContentNode = {
          type: 'p',
          content: pMatch[1].trim(),
          children: [],
          ellipsisShow: ellipsis > 0,
        };
        ellipsis--;
        h2Content.children.push(pContent);
      }
    }
    let pH1Match: RegExpExecArray | null;
    const pContentString = match[2].trim();
    while ((pH1Match = pH1Regex.exec(pContentString)) !== null) {
      const pContent: ContentNode = {
        type: 'p',
        content: pH1Match[1].trim(),
        children: [],
        ellipsisShow: ellipsis > 0,
      };
      ellipsis--;
      h1Content.children.push(pContent);
    }

    if (h1Content.children.length === 0) {
      h1Content.children.push({ type: 'raw', content: h2ContentString, children: [], ellipsisShow: ellipsis > 0 });
    }

    matches.push(h1Content);
  }
  return matches;
};

//  数组对象按照指定顺序排序
export const sortArrayByOrder = <T extends any[]>(
  arrObj: T,
  order: string[],
  property: T extends any[] ? keyof T[number] : never,
): T => {
  const orderMap = new Map(order.map((item, index) => [item, index]));

  return arrObj.sort((a, b) => {
    const orderA = orderMap.get(a[property]);
    const orderB = orderMap.get(b[property]);

    if (orderA === undefined && orderB === undefined) {
      return 0;
    }

    if (orderA === undefined) {
      return 1;
    }

    if (orderB === undefined) {
      return -1;
    }

    return orderA - orderB;
  });
};

// 滚动到页面顶部
export const scrollToTop = () => {
  if ('scrollBehavior' in document.documentElement.style) {
    // 如果浏览器支持 scroll-behavior 属性，使用平滑滚动
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    // 浏览器不支持 scroll-behavior 属性
    const sTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 4);
    }
  }
};

// 判断创建线索返回信息 是否已提交 重复进线
export const messageIsRepeat = (msg: string) => {
  if (msg) {
    return /已提交|已存在|exists|submit|submitted/.test(msg);
  }
  return false;
};

// 触发App登录 app登录返回Token
export const appLogin = (): Promise<string> => {
  // ios
  window?.webkit?.messageHandlers?.APP_LOGIN?.postMessage?.('');
  // 安卓
  window?.AppBridge?.APP_LOGIN?.('');
  return new Promise((resolve) => {
    window.loginResult = (result: string) => {
      resolve(result);
    };
  });
};

/**
 * 将字符串首字母转为大写
 *
 * @export
 * @param {string} str
 * @return {*}
 */
export const toInitialCapitalize = (str: string) => {
  if (!str) {
    return '';
  }
  const newStr = str.slice(0, 1).toUpperCase() + str.slice(1);
  return newStr;
};

export const isChristmasStyle = (time: number) => {
  // 在圣诞节公休假期日历上放置圣诞假期标识
  const year = 2023;
  const startChristmas = dayjs(`${year}-12-18`);
  const endChristmas = dayjs(`${year + 1}-01-03`);
  return dayjs(time).isAfter(startChristmas) && dayjs(time).isBefore(endChristmas);
};

export const isNewYearStyle = (time: number) => {
  const year = 2024;
  const newYear = dayjs(`${year}-01-01`);
  return dayjs(time).isSame(newYear, 'day');
};

export const isChrome = () => /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);

export const updateIntercomInfo = (currentStudent: StudentInfo) => {
  if (currentStudent?.uuid && currentStudent?.hmacSHA256AccountId) {
    const language = window.location.href.includes('/zh/') ? 'ZH' : 'EN';
    const {
      uuid,
      fullName,
      hmacSHA256AccountId,
      data,
      chinesePayStatus,
      englishPayStatus,
      mathPayStatus,
      country,
      code,
      payStatus,
    } = currentStudent || {};
    // 为 intercom 客服系统设置登录态
    const subjects = [];
    if (chinesePayStatus === 'PAID') {
      subjects.push('Chinese');
    }
    if (englishPayStatus === 'PAID') {
      subjects.push('ELA');
    }
    if (mathPayStatus === 'PAID') {
      subjects.push('Math');
    }
    window?.Intercom?.('update', {
      user_id: uuid,
      name: fullName,
      Country: country,
      City: data?.city,
      Subject: subjects.join(','),
      StudentCode: code,
      Language: language,
      PayStatus: payStatus,
      user_hash: hmacSHA256AccountId,
      AccountId: getTokenInfo()?.sub,
      StudentId: uuid,
    });
  }
};

export const hideIntercomUrls = [
  'app_android',
  'app_ios',
  'custom-block',
  'new-introduce',
  'courseconsumeinfo',
  'remark',
  'notification/adjustteacher',
  'notification/cancelschedule',
  'wechat-login',
  'trial-study-report',
];
// 暂时都不展示了, 不删除代码, 方便之后使用
export const advisorShowPaths = [];

/**
 * 根据语言不同的日期格式
 */

export const getDateByLang = (date: string, lang: string) => {
  return dayjs(date).format(lang === 'zh' ? 'YYYY-MM-DD hh:mmA' : 'MMMM D, YYYY hh:mm A');
};

/**
 *
 * @param count 数量
 * @param noun 英文
 * @returns 带格式的单位
 */
export const getUnit = (count: number, noun: string) => {
  if (+count === 1) {
    return noun; // 输出 "1 apple"
  }
  return `${noun}s`; // 输出 "2 apples"
};

/**
 * 货币转换千分位
 * @param { Number | String } num 要转换的数字
 * @param { Number } num 保留位数
 * @return { String }
 */
export const formatMoney = (num: number | string): string => {
  // if (!num) return '';
  // const digit = typeof num === 'string' ? parseFloat(num) : num;
  // const currencyStr = parseFloat(digit.toFixed(decimal)).toLocaleString('en-US');
  // const numberList = currencyStr.split('.');
  // let decimalStr;
  // if (numberList[1]) {
  //   decimalStr = numberList[1].length < decimal ? `${numberList[1]}0` : numberList[1];
  // } else {
  //   decimalStr = '00';
  // }
  // return `${numberList[0]}.${decimalStr}`;
  return '' + num;
};

export const beijingToLocalDate = (time: Date): Date => {
  return new Date(time.getTime() - 28800000 - new Date().getTimezoneOffset() * 60000);
};

const formatDate = (t: number) => {
  const hours = Math.floor(t / 1000 / 60 / 60);
  const minutes = Math.floor((t / 1000 / 60 / 60 - hours) * 60);
  const seconds = Math.floor(((t / 1000 / 60 / 60 - hours) * 60 - minutes) * 60);
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};

/**
 * 计算倒计时
 */
const durationTime = (endTime: Date) => {
  const time = endTime.getTime() - new Date().getTime();
  return formatDate(time);
};

const over24hours = (endTime: Date) => {
  return (new Date(endTime).getTime() - new Date().getTime()) / 1000 < 60 * 60 * 24;
};

export const overExpire = (endTime: Date) => {
  return new Date(endTime).getTime() - new Date().getTime() > 0;
};

export const getTimezoneDate = (expireTime: string) => {
  const timezoneLaebl = (new Date().getTimezoneOffset() / 60) * -1;
  const gmt = ` (GTM${timezoneLaebl > 0 ? '+' : ''}${timezoneLaebl})`;
  // 转换
  const localTime = beijingToLocalDate(new Date(expireTime));
  return {
    localTime,
    overExpireTime: !overExpire(localTime),
    over24hours: over24hours(localTime),
    endTimeLable: dayjs(localTime).format('YYYY-MM-DD HH:mm:ss') + gmt,
    durationTimeLabel: durationTime(localTime),
  };
};

export const isWhiteRoute = (paths: string[], pathname: string) => {
  return paths.some((path) => {
    return pathname === path;
  });
};

export const mainDomainTokenSet = (tokenKey?: string) => {
  Cookie.set(tokenKey ?? TOKEN, cacheStorage.getItem(TOKEN), {
    domain: window.location.href.includes('edu.net') ? EDU_URL : SCH_URL,
    path: '/',
  });
};

// div内是否含有元素
export const hasChildElements = (div: HTMLDivElement) => {
  return div?.children.length > 0;
};

/**
 * 邮箱脱敏处理
 *
 * @param email - 需要脱敏的邮箱
 * @returns 脱敏后的邮箱
 */
export const desensitizeEmail = (email: string | null | undefined): string => {
  if (!email) return '';
  const [localPart, domain] = email.split('@');
  const maskedLocalPart =
    localPart.length > 2
      ? localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1]
      : localPart[0] + '*';

  return `${maskedLocalPart}@${domain}`;
};

/**
 * 手机号脱敏处理
 *
 * @param phoneNumber - 需要脱敏的手机号
 * @returns 脱敏后的手机号
 */
export const desensitizePhoneNumber = (phoneNumber: string): string => {
  // 匹配国家码（+开头，后面跟1-4个数字），后面可能跟着空格
  const countryCodeMatch = phoneNumber.match(/^\+\d{1,4}(?=\s|\d)/);
  const countryCode = countryCodeMatch ? countryCodeMatch[0] : '';

  // 移除国家码后的主号码部分，去掉空格
  const mainNumber = phoneNumber.slice(countryCode.length).replace(/\s+/g, '');

  if (mainNumber.length > 6) {
    const start = mainNumber.substring(0, 3);
    const end = mainNumber.substring(mainNumber.length - 3);
    const maskedSection = '*'.repeat(mainNumber.length - 6);

    // 返回保留国家码和空格的脱敏结果
    return `${countryCode} ${start}${maskedSection}${end}`;
  }

  return `${countryCode} ${mainNumber.replace(/\d/g, '*')}`;
};
