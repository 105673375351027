import React from 'react';
import cls from 'classnames';
import Pagination from 'rc-pagination';

import 'rc-pagination/assets/index.css';

import pageRightIcon from '@public/imgs/icon-page-right.svg';
import styles from './style.module.scss';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
import router from 'next/router';

export interface MPaginationProps {
  className?: string;
  total: number;
  current: number;
  pageSize: number;
  showSizeChanger?: boolean;
  onChangeCurrent: (value: number) => void;
}

const MPagination = (props: MPaginationProps) => {
  const { total, current, onChangeCurrent, pageSize, className, showSizeChanger = false } = props;
  const { traceEventWithAccount } = useTraceEventWithAccount();

  const ItemRender = (page: number, type: string, element: React.ReactNode) => {
    switch (type) {
      case 'prev':
        return (
          <div className={styles.preIcon}>
            <img src={pageRightIcon.src} alt="" />
          </div>
        );
      case 'next':
        return (
          <div className={cls(styles.preIcon, styles.nextIcon)}>
            <img src={pageRightIcon.src} alt="" />
          </div>
        );
      case 'page':
        return <div className={cls(styles.page, page === current && styles.pageActive)}>{page}</div>;
      default:
        return element;
    }
  };

  return (
    <>
      <Pagination
        className={cls(styles.pagination, className)}
        itemRender={ItemRender}
        onChange={(value) => {
          traceEventWithAccount({
            _event: 'U_PageTurningClick',
            page_name: router.pathname,
          });
          onChangeCurrent(value);
        }}
        current={current}
        pageSize={pageSize}
        total={total}
        showSizeChanger={showSizeChanger}
      />
    </>
  );
};

export default MPagination;
