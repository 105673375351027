import React from 'react';

import styles from './style.module.scss';
import classNames from 'classnames';

const PageWrapper: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.content, 'bg-[#F7F7F7] lg:bg-transparent')}>{children}</div>
    </div>
  );
};

export default PageWrapper;
