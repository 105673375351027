import React, { useCallback, useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Parser, Player } from 'svga';

import styles from './style.module.scss';

export interface LoadingProps {
  loading: boolean;
  opacity?: number;
  fullscreen?: React.ReactNode;
  className?: string;
  maskClassName?: string;
}

const Loading = (props: LoadingProps) => {
  const { loading = false, fullscreen, opacity = 1, className, maskClassName } = props;
  const svgaRef = useRef<HTMLCanvasElement>(null);
  const [player, setPlayer] = useState<Player>();

  useEffect(() => {
    if (loading) {
      player?.start();
    } else {
      player?.stop();
    }
  }, [loading, player]);

  const init = useCallback(async () => {
    if (svgaRef?.current) {
      const parser = new Parser({ isDisableWebWorker: true });
      const svga = await parser.load('https://cdnwukong.com/images/student-center/loading.svga');
      const svgaPlayer = svgaRef?.current && new Player(svgaRef?.current);
      setPlayer(svgaPlayer);
      await svgaPlayer?.mount(svga);
    }
  }, [svgaRef]);

  useEffect(() => {
    init();
    return () => {
      setPlayer(undefined);
    };
  }, [init]);

  return (
    <CSSTransition
      appear
      timeout={300}
      in={loading}
      classNames={{
        appear: styles.loadingEnter,
        appearActive: styles.loadingEnterActive,
        exit: styles.loadingEnterActive,
        exitActive: styles.loadingExitActive,
      }}
    >
      <div
        className={cls(
          styles.loading,
          !fullscreen && styles.loadingNode,
          loading ? maskClassName : styles.loadingIndex,
        )}
        style={{ opacity }}
      >
        <canvas className={cls(styles.content, className)} ref={svgaRef} />
      </div>
    </CSSTransition>
  );
};

export default Loading;
