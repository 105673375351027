import create from 'zustand';
import cloneDeep from 'lodash/cloneDeep';
import { combine } from 'zustand/middleware';
import { StudentInfo } from '@/api/types/home';
import { User } from '@/api/types/login';
import { getAccountInfo, getParentVerification, getStudentList } from '@/api/home';
import { Toast } from '@/components';
import { getAddress } from '@/api/order';
import { IpInfo } from 'types/order';

interface UserContextType {
  curStudent: StudentInfo | null;
  students: StudentInfo[] | null;
  account: User | null;
  reserveFlag: boolean;
  isParentVerify: boolean;
  firstLoadParentVerify: boolean | null;
  isCompletedParentalVerification: boolean;
  emailStatus: 'PENDING' | 'DONE' | 'FAILED' | 'NONE';
  parentVerifyLoading: boolean;
  currentIpConfig: IpInfo | null;
  isGs2User: boolean | undefined;
}

interface UserActionType {
  updateStudents(students: StudentInfo[]): void;
  updateCurStudent(student: StudentInfo): void;
  updateAccount(account: User | null): void;
  clear(): void;
  updateReserveFlag(value: boolean): void;
  remoteUpdateAccount(): void;
  remoteUpdateStudents(): void;
  updateParentalVerification(msg?: string): void;
  updateIpConfig(): void;
  setIsGs2User: (value: boolean) => void;
}

const initialState: UserContextType = {
  curStudent: null,
  students: null,
  account: null,
  reserveFlag: false,
  isParentVerify: false,
  firstLoadParentVerify: null,
  isCompletedParentalVerification: false,
  emailStatus: 'NONE',
  parentVerifyLoading: false,
  currentIpConfig: null,
  isGs2User: undefined,
};

const useUser = create(
  combine<UserContextType, UserActionType>(initialState, (set) => ({
    updateStudents: (students: StudentInfo[]) => {
      set((state) => ({
        students,
        curStudent: cloneDeep(
          state?.curStudent
            ? students?.find(({ uuid }) => uuid === state.curStudent?.uuid) || state.curStudent
            : students?.find(({ currentStudent }) => currentStudent) || students?.[0],
        ),
      }));
    },
    updateCurStudent: (student: StudentInfo) => {
      set(() => ({
        curStudent: student,
      }));
    },
    setIsGs2User: (value: boolean) => {
      set(() => ({
        isGs2User: value,
      }));
    },
    clear: () => {
      set(() => ({
        curStudent: null,
        students: null,
        account: null,
      }));
    },
    updateAccount: (account: User) => {
      set(() => ({
        account,
      }));
      getParentVerification().then((res) => {
        set(() => ({
          firstLoadParentVerify: res === 'DONE',
          isParentVerify: res === 'DONE',
          isCompletedParentalVerification: res !== 'DONE',
          emailStatus: res,
        }));
      });
    },
    updateParentalVerification: (msg?: string) => {
      set(() => ({
        parentVerifyLoading: true,
      }));
      getParentVerification().then((res) => {
        set((state) => ({
          isParentVerify: res === 'DONE',
          isCompletedParentalVerification: !state.firstLoadParentVerify && res !== 'DONE',
          emailStatus: res,
        }));
        let timer = setTimeout(() => {
          clearTimeout(timer);
          set(() => ({
            parentVerifyLoading: false,
          }));
        }, 1000);
        msg && Toast.success(msg);
      });
    },
    updateIpConfig: () => {
      set(() => ({
        currentIpConfig: null,
      }));
      getAddress().then((defaultAdr: IpInfo) => {
        set(() => ({
          currentIpConfig: defaultAdr,
        }));
      });
    },
    updateReserveFlag: (value: boolean) => {
      set(() => ({
        reserveFlag: value,
      }));
    },
    remoteUpdateAccount: () => {
      getAccountInfo().then((res) => {
        set(() => ({
          account: res,
        }));
      });
    },
    remoteUpdateStudents: () => {
      getStudentList().then((res) => {
        set((state) => ({
          students: res,
          curStudent: cloneDeep(
            state?.curStudent
              ? res?.find(({ uuid }) => uuid === state.curStudent?.uuid) || state.curStudent
              : res?.find(({ currentStudent }) => currentStudent) || res?.[0],
          ),
        }));
      });
    },
  })),
);

export default useUser;
