/* eslint-disable import/no-cycle */
import ReactDOM from 'react-dom';
import React from 'react';
import Modal from './index';

import { IModalConfirmParams } from './interface';
import CommonApiModal from './commonApiModal';

class ApiConfirm extends CommonApiModal<IModalConfirmParams> {
  render(visible: boolean, options: IModalConfirmParams): void {
    const {
      title,
      onCancel,
      onOk,
      cancelText = '取消',
      okText = '确认',
      cancelProps,
      okProps,
      content,
      className,
      mask,
      onClose,
      disableBodyScroll,
      closeBtn = true,
      zIndex,
      maskZIndex,
      bodyClassName,
      renderTitle,
    } = options;
    ReactDOM.render(
      <Modal
        zIndex={zIndex}
        maskZIndex={maskZIndex}
        visible={visible}
        title={title}
        mask={mask}
        closeBtn={closeBtn}
        className={className}
        onExited={(): void => this.destroy()}
        disableBodyScroll={disableBodyScroll}
        bodyClassName={bodyClassName}
        renderTitle={renderTitle}
        footers={[
          {
            content: cancelText,
            onClick: (): void => {
              this.render(false, options);
              onCancel?.();
            },
            color: 'red',
            ...cancelProps,
          },
          {
            content: okText,
            onClick: (): void => {
              this.render(false, options);
              onOk?.();
            },
            color: 'red',
            ...okProps,
          },
        ]}
        onClose={(): void => {
          onClose?.();
          this.render(false, options);
        }}
      >
        {content}
      </Modal>,
      this.apiModalContainer,
    );
  }
}

export default ApiConfirm;
