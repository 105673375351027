import React from 'react';
import cls from 'classnames';

import styles from './style.module.scss';

export interface NoDataProps {
  icon: string;
  tip: string | React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  isBig?: boolean;
}

const NoData = (props: NoDataProps) => {
  const { icon, tip, className, wrapperClassName, isBig = false } = props;
  return (
    <div className={cls(styles.nodataWrapper, wrapperClassName)}>
      <div className={cls(styles.box, { [styles.big]: isBig }, className)}>
        <img src={icon} alt="" />
        <span>{tip}</span>
      </div>
    </div>
  );
};

export default NoData;
