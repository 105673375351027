import React, { useMemo, useCallback } from 'react';
import { useSprings, animated } from 'react-spring';

import Star from './star';
import styles from './style.module.scss';

export interface StarsProps {
  count: number;
  preview: boolean;
  activeCount: number;
  starClassName?: string;
  className?: string;
  onChange: (value: number) => void;
}

const Stars = (props: StarsProps) => {
  const { preview, count, activeCount, starClassName, onChange } = props;

  const stars = useMemo(() => {
    const results = [];

    for (let i = 0; i < count; i += 1) {
      results.push({ active: i < activeCount, disabled: i >= activeCount && preview });
    }
    return results;
  }, [count, activeCount, preview]);

  const [springs] = useSprings(activeCount || 0, (i) => ({
    from: {
      scale: 0,
    },
    scale: 1,
    config: { friction: 16, tension: 200 },
    delay: i * 100,
  }));

  const handleChange = useCallback(
    (value: number) => {
      if (!preview) {
        onChange(value);
      }
    },
    [preview, onChange],
  );
  return (
    <div className={styles.stars}>
      {activeCount > 0 &&
        springs?.map((style, index) => (
          <animated.span style={style} key={index} onClick={() => handleChange(index + 1)}>
            <Star disabled={stars[index].disabled} active={stars[index].active} className={starClassName} />
          </animated.span>
        ))}
      {stars.slice(activeCount)?.map(({ disabled, active }, index) => (
        <span role="button" key={index} onClick={() => handleChange(activeCount + index + 1)}>
          <Star disabled={disabled} active={active} className={starClassName} />
        </span>
      ))}
    </div>
  );
};

export default Stars;
