import React from 'react';
import cls from 'classnames';

import styles from './style.module.scss';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  prefixCls?: string;
  suffixCls?: string;
  size?: 'small' | 'large' | 'medium';
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  tipBorder?: boolean; // 是否需要标红输入框
}

const Input: React.FC<InputProps> = (props) => {
  const {
    className,
    prefix,
    suffix,
    prefixCls,
    suffixCls,
    size = 'medium',
    onKeyDown,
    onPressEnter,
    width,
    tipBorder = false,
    ...restProps
  } = props || {};
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.keyCode === 13) {
      onPressEnter?.(event);
    }
    onKeyDown?.(event);
  };

  return (
    <div
      style={{ width }}
      className={cls(
        styles.inputWrapper,
        styles[`input-wrapper-${size}`],
        tipBorder && styles.inputWrapperTipBorder,
        className,
      )}
    >
      {prefix && <div className={prefixCls}>{prefix}</div>}
      <input autoComplete="new-password" className={styles.input} onKeyDown={handleKeyDown} {...restProps} />
      {suffix && <div className={suffixCls}>{suffix}</div>}
    </div>
  );
};

export default Input;
