/* eslint-disable import/no-cycle */
import React, { useMemo, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import cls from 'classnames';

import ApiConfirm from './apiConfirm';
import ApiInfo from './apiInfo';
import ApiAlert from './apiAlert';
import {
  IApiModal,
  IModalAlertParams,
  IModalConfirmParams,
  IModalFooterItem,
  IModalInfoParams,
  IModalProps,
} from './interface';
import styles from './style.module.scss';
import Mask from '../Mask';
import Button from '../Button';
import closeSvg from './close.svg';

const Modal = (props: React.PropsWithChildren<IModalProps>) => {
  const {
    className,
    bodyClassName,
    visible,
    image,
    imageStyle,
    renderTitle,
    footers,
    onClose,
    title,
    closeBtn = true,
    children = '',
    onEntered,
    onExited,
    mask = true,
    disableBodyScroll = false,
    container = document.body,
    unmountOnExit = false,
    zIndex,
    maskZIndex,
  } = props || {};
  const [inProp, setInProp] = useState(false);
  useEffect(() => {
    setInProp(visible || false);
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const overflowBuffer = document.body.style.overflow;
      if (disableBodyScroll) {
        document.body.style.overflow = 'hidden';
      }
      return (): void => {
        document.body.style.overflow = overflowBuffer;
      };
    }
    return () => {
      // nothing return
    };
  }, [disableBodyScroll, visible]);

  const modalTitle = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }
    if (title) {
      return <div className={styles.modalTitle}>{title}</div>;
    }
    return null;
  }, [title, renderTitle]);

  return ReactDOM.createPortal(
    <>
      {visible && mask ? <Mask zIndex={maskZIndex} className={styles.modalMask} /> : null}
      <CSSTransition
        in={inProp}
        timeout={600}
        onEntered={onEntered}
        onExited={onExited}
        classNames={{
          enter: styles.modalEnter,
          enterActive: styles.modalEnterActive,
          enterDone: styles.modalEnterDone,
          exit: styles.modalEnter,
          exitActive: styles.modalExit,
        }}
        unmountOnExit={unmountOnExit}
      >
        <div
          className={cls(styles.modalWrap, {
            [styles.modalHasTitle]: title,
          })}
          style={{
            zIndex,
          }}
        >
          <div className={cls(styles.modalContainer, className)}>
            {modalTitle}
            {closeBtn ? (
              // eslint-disable-next-line jsx-a11y/interactive-supports-focus
              <div className={styles.modalClose} role="button" onClick={onClose}>
                <img src={closeSvg.src} alt="close" />
              </div>
            ) : null}

            <div className={cls(styles.modalBody, bodyClassName)}>
              {/* todo icon */}
              {image ? (
                <div className={styles.modalIcon} style={imageStyle}>
                  <img src={image} alt="" />
                </div>
              ) : null}
              <div className={styles.modalContent}>{children}</div>
            </div>
            <div className={styles.modalFooter}>
              {footers?.map((item: IModalFooterItem, index: number) => {
                return (
                  <Button
                    className={cls(styles.modalFooterButton, item.className)}
                    color={item.color}
                    key={index}
                    disabled={item.disable}
                    onClick={item.onClick}
                    shieldIcon={item.shieldIcon}
                  >
                    {item.content}
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>,
    container,
  );
};

Modal.alert = (params: IModalAlertParams): IApiModal<IModalAlertParams> => {
  return new ApiAlert(params);
};

Modal.confirm = (params: IModalConfirmParams): IApiModal<IModalConfirmParams> => {
  return new ApiConfirm(params);
};

Modal.info = (params: IModalInfoParams): IApiModal<IModalInfoParams> => {
  return new ApiInfo(params);
};

export default Modal;
