import React from 'react';
import cls from 'classnames';

import starIcon from '@public/imgs/star-active.png';
import styles from './style.module.scss';

export interface StarProps {
  active: boolean;
  disabled?: boolean;
  className?: string;
}

const Star = ({ active, className, disabled }: StarProps) => {
  return (
    <div>
      {!active ? (
        <svg
          className={cls(className, disabled && styles.starDisabled)}
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.3892 2.42334C11.5368 0.387411 14.4686 0.38741 15.6161 2.42334L18.1869 6.98442L23.3192 8.01996C25.6101 8.4822 26.5161 11.2705 24.9344 12.991L21.391 16.8455L21.9921 22.0466C22.2604 24.3682 19.8885 26.0915 17.7634 25.1189L13.0027 22.94L8.24191 25.1189C6.11684 26.0915 3.74494 24.3682 4.01325 22.0466L4.61436 16.8455L1.07094 12.991C-0.510744 11.2705 0.395241 8.4822 2.68614 8.01996L7.81841 6.98442L10.3892 2.42334Z"
            fill="#E6E6E6"
          />
        </svg>
      ) : (
        <img className={cls(className, disabled && styles.starDisabled)} src={starIcon.src} alt="" />
      )}
    </div>
  );
};

export default Star;
