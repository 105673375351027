import * as process from 'process';

export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || '';

export const WECHAT_CODE = process.env.NEXT_PUBLIC_WECHAT_CODE || '';

export const DM_ZHONGWEN_URL = process.env.NEXT_PUBLIC_DM_ZHONGWEN_URL || '';

export const HOMEWORK_URL = () => {
  return window.location.href.includes('edu.net')
    ? process.env.NEXT_PUBLIC_HOMEWORK_URL
    : process.env.NEXT_PUBLIC_HOMEWORK_URL?.replace('edu.net', 'sch.com');
};

export const IEXERICISE = process.env.NEXT_PUBLIC_HOMEWORK_URL;

export const IS_PROD = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

export const WECHAT_APPID = process.env.NEXT_PUBLIC_WECHAT_APPID || 'wxf3acbfbbdef5ddcd';

export const AUTH_REDIRECT_URI = process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI || '';

export const COMMON_API_HOST = process.env.NEXT_PUBLIC_COMMON_API_HOST || '';

export const GATEWAY_HOST = process.env.NEXT_PUBLIC_GATEWAY_HOST || '';

export const CHINESE_LAND_URL = process.env.NEXT_PUBLIC_CHINESE_LAND_URL || '';

export const MATH_LAND_URL = process.env.NEXT_PUBLIC_MATH_LAND_URL || '';

export const ENGLISH_LAND_URL = process.env.NEXT_PUBLIC_ENGLISH_LAND_URL || '';

export const WK_GATEWAY_URL = process.env.NEXT_PUBLIC_WK_GATEWAY_API_HOST || '';

export const CA_STUDIO_URL = process.env.NEXT_PUBLIC_CA_STUDIO_API_URL || '';

export const RS_GATEWAY_URL = process.env.NEXT_PUBLIC_RS_GATEWAY_API_HOST || '';

export const WUKONG_URL = () => {
  return window.location.href.includes('edu.net')
    ? process.env.NEXT_PUBLIC_WUKONG_URL
    : process.env.NEXT_PUBLIC_WUKONG_URL?.replace('edu.net', 'sch.com');
};

export const AGORA_URL = process.env.NEXT_PUBLIC_AGORA_URL ?? '';

export const CRM_API_HOST = process.env.NEXT_PUBLIC_CRM_API_HOST || '';

export const WUKONG_DOMAIN = process.env.WUKONG_DOMAIN || 'wktest';

export const payment_url = () => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    return window.location.href.includes('edu.net') ? 'https://pay.wukongedu.net' : 'https://pay.wukongsch.com';
  }

  return 'https://pay-test.wukongedu.net';
};
