/* eslint-disable import/no-cycle */
import ReactDOM from 'react-dom';
import React from 'react';

import { IModalAlertParams } from './interface';
import CommonApiModal from './commonApiModal';

import Modal from './index';

class ApiAlert extends CommonApiModal<IModalAlertParams> {
  render(visible: boolean, options: IModalAlertParams): void {
    const {
      title,
      onOk,
      onClose,
      content,
      okProps,
      okText = '确定',
      image,
      className,
      imageStyle,
      disableBodyScroll,
      mask,
      closeBtn = true,
      zIndex,
      maskZIndex,
      bodyClassName,
      renderTitle,
    } = options;

    ReactDOM.render(
      <Modal
        visible={visible}
        className={className}
        title={title}
        zIndex={zIndex}
        maskZIndex={maskZIndex}
        mask={mask}
        closeBtn={closeBtn}
        onExited={(): void => this.destroy()}
        image={image}
        imageStyle={imageStyle}
        disableBodyScroll={disableBodyScroll}
        bodyClassName={bodyClassName}
        renderTitle={renderTitle}
        footers={[
          {
            content: okText,
            color: 'red',
            onClick: (): void => {
              this.render(false, options);
              onOk?.();
            },
            ...okProps,
          },
        ]}
        onClose={(): void => {
          this.render(false, options);
          onClose?.();
        }}
      >
        {content}
      </Modal>,
      this.apiModalContainer,
    );
  }
}

export default ApiAlert;
