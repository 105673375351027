import React, { useCallback, useState } from 'react';
import cls from 'classnames';

import styles from './style.module.scss';

export interface DropdownProps {
  className?: string;
  trigger?: 'hover';
  optionClassName?: string;
  optionChildren: React.ReactNode;
  mouseEnterTraceClick?: () => void; // 鼠标移入的埋点
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { children, className, optionChildren, trigger = 'hover', optionClassName, mouseEnterTraceClick } = props || {};
  const [showOpts, setShowOpts] = useState(false);

  const handleMouseEnter = useCallback(() => {
    mouseEnterTraceClick?.();
    if (window.innerWidth < 1024) {
      return;
    }
    if (trigger === 'hover') {
      setShowOpts(true);
    }
  }, [trigger]);

  const handleMouseOut = useCallback(() => {
    if (trigger === 'hover') {
      setShowOpts(false);
    }
  }, [trigger]);

  const handleCLick = () => {
    if (window.innerWidth >= 1024) {
      return;
    }
    setShowOpts(!showOpts);
  };

  return (
    <div className={styles.dropdownWrapper}>
      <div
        role="button"
        onClick={handleCLick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseOut}
        className={cls(styles.wrapper, className)}
      >
        {children}
      </div>
      <div role="button" className={cls(styles.options, optionClassName, showOpts && styles.optionsShow)}>
        {optionChildren}
      </div>
    </div>
  );
};

export default Dropdown;
