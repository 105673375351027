/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';

export const isAppEnv = () => typeof window !== 'undefined' && !!window?.AppBridge;
export interface DeviceInfo {
  token: string;
}

export const useGetDevice = (): [string, DeviceInfo] => {
  const [version, setVersion] = useState('');
  const [info, setInfo] = useState<DeviceInfo>({ token: '' });

  useEffect(() => {
    if (isAppEnv()) {
      const { platform, appVersion: version, Authorization } = window?.AppBridge || {};

      // 安卓无法直接从AppBridge拿到数据 需要callback的形式进行数据捕获
      if (!platform) {
        window.deviceInfo = (result: {
          platform: string;
          safeArea: { top: number; left: number; bottom: number; right: number };
          version: string;
          device: {
            deviceModel: string;
            deviceName: string;
            deviceManuFacturer: string;
            authorization: string;
            version: string;
          };
        }) => {
          setVersion(result?.device?.version);
          setInfo((prev) => ({ ...prev, token: result?.device?.authorization?.split(' ')?.pop() ?? '' }));
        };

        window?.AppBridge?.GET_DEVICE_INFO?.({});
      } else {
        setVersion(version);
        setInfo((prev) => ({ ...prev, token: Authorization }));
      }
    }
  }, [isAppEnv]);

  // 平台
  return [version, info];
};

export const compareVersions = (currentVersion: string, specifiedVersion: string): boolean => {
  const currentParts = currentVersion.split('.').map(Number);
  const specifiedParts = specifiedVersion.split('.').map(Number);

  // Ensure both version numbers have the same number of parts
  while (currentParts.length < specifiedParts.length) {
    currentParts.push(0);
  }
  while (specifiedParts.length < currentParts.length) {
    specifiedParts.push(0);
  }

  // Compare each part of the version number
  for (let i = 0; i < currentParts.length; i++) {
    if (currentParts[i] > specifiedParts[i]) {
      return true;
    }
    if (currentParts[i] < specifiedParts[i]) {
      return false;
    }
  }

  // The version numbers are equal
  return true;
};
