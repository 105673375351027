import useUser from '@/store/useUser';
import { traceEvent } from '@wk/wk-gatherer';

const useTraceEventWithAccount = () => {
  const { curStudent, account } = useUser();

  const traceEventWithAccount = (rest: { [key: string]: any }) => {
    traceEvent({
      data: {
        account_id: account?.uuid,
        Code: curStudent?.code,
        ...(rest || {}),
      },
    });
  };

  return { traceEventWithAccount };
};

export default useTraceEventWithAccount;
